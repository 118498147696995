@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./styles/color.css");
@import "antd/dist/antd.css";
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* -webkit-text-fill-color: white; */
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.scrollbar {
  width: 1em;
  height: 5px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background-color: white !important;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #979797 !important;
  border-radius: 10px;
  width: 5px;
  height: 5px;
}
.ant-table-thead .ant-table-cell {
  background-color: #0040ac1a;
  color: #000;
  border-top-left-radius: 0px !important;
}
.ant-table-tbody .ant-table-cell {
  background-color: #f8f8f84d;
  color: #000;
  line-height: 1rem !important;
}
tbody > tr:hover > td {
  background-color: #f8f8f84d;
}
.ant-table-tbody > tr > td {
  border: none;
}
.ant-table-thead > tr > th {
  border: none !important;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px !important;
}
html {
  /* this makes sure the padding and the border is included in the box sizing */
  overflow-x: hidden;
}
.demo-editor {
  background-color: rgb(57, 57, 57);
  color: white;
  height: 20rem !important;
  padding: 0 1rem;
}
.MuiOutlinedInput-root {
  border-radius: 10px !important;
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
