:root {
  --primary-color: #1e70fc;
  --primary-hover-color: #1e6ffce8;
  --secondary-color: #cd590b;
  --gray-9a: #9a9a9a;
  --gray-bf: #bfbfbf;
  --gray-c9: #c9c9c9;
  --red-error: #ff0000;
  --gray-color-43: #464343;
  --gray-color-77: #777777;
  --white-color: #ffffff;
}
