.ant-select,
.ant-select-selector,
.ant-select-selection-search {
  background: #0040ac !important;
  color: white !important;
  border-radius: 6px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 30px; */
  border: none !important;
}

.ant-select-arrow {
  background: #0040ac !important;
  color: white !important;
}
