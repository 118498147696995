.alarmButton {
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  margin-right: 2rem;
}

.modal {
  width: 300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.modalContent {
  padding: 10px 0;
}

.notificationItem {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.notificationItem:last-child {
  border-bottom: none;
}

.notificationText {
  font-size: 14px;
  color: #333;
}

.notificationDate {
  font-size: 12px;
  color: #1a237e;
  margin-top: 5px;
}

.closeButton {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.closeButton:hover,
.closeButton:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
